import React from "react";
import Main from "./Main/Main";
import { Helmet } from 'react-helmet'

export default () => (
  <div>
      <Main/>
  </div>
  
);
